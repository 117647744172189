import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { appstoreUrl } from "../constants"
import Footer from "../components/Footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Head from "../components/Head"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const image = getImage(post.frontmatter.hero_image)

  console.log(data, post)

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div className="center" style={{ maxWidth: "1516px" }}>
        <Head showBlog />
      </div>
      <div className="w-100 h5 flex flex-column items-center justify-center tc">
        <GatsbyImage
          image={image}
          alt={post.frontmatter.hero_image_alt}
          width="100%"
          layout="fluid"
        />
      </div>
      <article
        className="blog-post mw7 center pa3"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline" className="f1">{post.frontmatter.title}</h1>
          <p className="f4 gray">{post.frontmatter.date}</p>
        </header>
        <hr />
        <section
          className={`blog-post ${post.fields.slug.replace(/\//g, '')}`}
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      </article>
      <div className="mw7 center tc pa3 bg-near-white br4">
        <h1 className="dark-gray mb3">Better clips for TikTok and Instagram!</h1>
        <div className="f4 dark-gray lh-copy mb3">
          StreamKit makes it easy to edit and share your Twitch clips for TikTok, Instagram, and Youtube. Try it for free!
        </div>
        <div className="mw5 center">
          <a href={appstoreUrl} className="no-underline center w-auto">
            <div className="grow pointer bg-primary white b f4 tc br3 pa3 ">Get Started!</div>
          </a>
        </div>
      </div>
        <div className="w-100 tc mt4">
          <a className="f4 center primary center" href="/blog">See more blog posts →</a>
        </div>
      <section className="bg-white w-100 pv3 mt4 ph3 bg-primary">
        <div className="center" style={{ maxWidth: "75rem" }}>
          <Footer white />
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
        description
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      fields {
        slug
      }
    }
  }
`
